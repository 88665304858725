<template>
  <div></div>
</template>

<script>
import {io} from "socket.io-client";
import {mapGetters} from "vuex";
let emitter = require('tiny-emitter/instance');
export default {
  name: "LmsWebSocket",
  data(){
    return {
      socket: null,
      connected: false,
      users: [],
      usersStreams: {},

    }
  },
  mounted(){

    this.connectServer();
  },
  methods: {
    connectServer() {

      if (!this.socket) {
        this.connected = true;

        this.socket = io(this.switch.url_chat, {
          transports: ["websocket"],
          autoConnect: true,
          reconnection: true,
        });
        this.socket.on("userConnected", this.userConnected);
        this.socket.on("userDisconnected", this.userDisconnected);
        // this.socket.on("updateUserList", this.updateUserList);
        // this.socket.on("message", this.onMessage);
        this.socket.on("notify", this.onNotify);
        // this.socket.on("broadcast", this.onBroadcast);
        // this.socket.on("fullRoom", this.fullRoom);
        this.socket.on("disconnect", this.disconnected);
        this.socket.emit("identify", {
          userId: this.id,
          userName: this.nickname,
          jwt: this.token,
        });
        this.socket.on("connect", this.connect);
      }
    },
    connect() {

      if (!this.connected) {
        this.joinRoom(
            this.currentRoom.room,
            this.currentRoom.roomUsersLimit,
            this.currentRoom.hasAudioStream,
            this.currentRoom.autoModerate
        );
        this.connected = true;
      }
    },
    userConnected({ userId, userName, peerId }) {
      let self = this;
      self;

      this.users.push({
        id: userId,
        name: userName,
        status: "on-line",
        peerId,
      });
      /*
      if (this.roomHasAudioStream) {
        //console.log("Call", peerId, this.media);
        setTimeout(() => {
          let call = this.peer.call(peerId, this.media);
          if (call) {
            call.on("stream", function (stream) {
              //console.log("Call answerer", stream);
              // check if you can speak
              self._createAudioElement({ muted: false, stream, userId });
            });
          }
        }, 1000);
      }
       */
    },
    userDisconnected(userId) {
      //console.log("User disconnected", userId);
      let users = this.users;
      let index = users.findIndex((user) => user.id === userId);
      if (index >= 0) {
        if (users[index].audioElement) users[index].audioElement.remove();
        if (this.usersStreams[userId]) delete this.usersStreams[userId];
        users.splice(index, 1);
      }
    },
    disconnected() {
      this.connected = false;
      this.users = [];
    },
    onNotify(payload) {
      payload = JSON.parse(payload);
      const root_ = this;
      // console.log("onNotify: ", payload);
      let notificationId = payload?.notification_id ?? payload.object?.notification_id;

      switch (payload.type) {

        case "lms_finished_lesson":
        case "lms_finished_section":
        case "lms_finished_course":
          // console.log('LESSON FINISHED WEBSOCKET =>', payload);
          root_.$store.dispatch("update_websocket_notifications_pendent_ids", notificationId);
          emitter.emit('notifications::new-notifications-available', payload);
          // root_.$parent.notifications_count++;
          // root_.$parent.update_notifications(payload);
          // root_.$parent.updatePopupNotifications();
          break;
        case "package_payment":
          // console.log('package_payment WEBSOCKET =>', payload);
          root_.$store.dispatch("update_websocket_notifications_pendent_ids", notificationId);
          emitter.emit('notifications::new-notifications-available', payload);
          break;
        case "cart_order":
          // console.log('cart_order WEBSOCKET =>', payload);
          root_.$store.dispatch("update_websocket_notifications_pendent_ids", notificationId);
          emitter.emit('notifications::new-notifications-available', payload);
          break;
        case "etalent_report":
          // console.log('etalent_report WEBSOCKET =>', payload);
          root_.$store.dispatch("update_websocket_notifications_pendent_ids", notificationId);
          emitter.emit('notifications::new-notifications-available', payload);
          emitter.emit('etalentReport::response', payload);
          break;
        case "friend_request":
          root_.$store.dispatch("update_websocket_notifications_pendent_ids", notificationId);
          emitter.emit('notifications::new-notifications-available', payload);

          break;
        case "admin": // Only that has subtype
          switch (payload.subtype){
            case "check_tab":
              var dat__ = new Date(payload.dat);
              if (root_.$parent.age_get_user === false) {
                if (
                    String(root_.$parent.dat) !== "null" &&
                    String(dat__) !== String(root_.$parent.dat)
                ) {
                  root_.$parent.modal(
                      "error",
                      this.$i18n.t('app.multiplasJanelas'),
                      this.$i18n.t('app.voceFoiDesconectado'),
                      null,
                      null,
                      null,
                      "OK"
                  );
                  root_.$parent.logoutEnd();
                }
              }
              if (this.age_get_user === true) {
                this.age_get_user = false;
              }
              break;

            case "private_message":
              // console.log('admin / private message =>', payload);

              // se o modal esta aberto e conversando com o respectivo amigo, cria a mensagem e atualiza o status da notificacao para reed
              if( this.$parent.modal_private_messages && this.$parent.private_messages_friend_id == payload.user.id ) {
                this.$parent.$refs.pm_messages.createMessageElement(payload.message);
                // form_data.append('user_notification_flag', 'reed');  // já é reed como padrão
              } else if (this.$route.name == 'LmsFriends') {
                if(this.$parent.$refs.main.$refs.inbox.openMessages && this.$parent.$refs.main.$refs.inbox.privateMessagesUser == payload.user.id) {
                  this.$parent.$refs.main.$refs.inbox.$refs['private-messages'].createMessageElement(payload.message)
                } else {
                  this.$parent.$refs.main.$refs.inbox.updateInbox(payload);
                }
              } else {
                root_.$store.dispatch("update_websocket_notifications_pendent_ids", notificationId);
                emitter.emit('notifications::new-notifications-available', payload);
              }
              break;

            case "point_coin_update":
              if (Number(this.TotalCoins) != Number(payload.coins)) {
                  if (payload.coins !== "-1") {
                    root_.$parent.updateUser("TotalCoins", payload.coins);
                    root_.$parent.update_coin(payload.coins);
                  }
              }
              if (Number(this.TotalPoints) != Number(payload.points)) {
                  if (payload.points !== "-1") {
                    root_.$parent.updateUser("TotalPoints", payload.points);
                    root_.$parent.update_point(payload.points);
                  }
              }
              break;

            case "logout":
              // // var now = new Date();
              // // var past = root_.$parent.login_date;
              // // var diff = Math.abs(now.getTime() - past.getTime());
              // // if (diff > 5) {
              //   root_.$parent.modal(
              //     "error",
              //     root_.$i18n.t('app.loginsMultiplos'),
              //     root_.$i18n.t('app.voceFoiDesconectadoPois'),
              //     null,
              //     null,
              //     null,
              //     "OK"
              //   );
              //   root_.$parent.logoutEnd();
              // // }
              break;
          } // End payload.subtype

          break; // End admin

        // case "private_message":
        //   console.log('private message =>', payload);
        //
        //   // se o modal esta aberto e conversando com o respectivo amigo, cria a mensagem e atualiza o status da notificacao para reed
        //   if( this.$parent.modal_private_messages && this.$parent.private_messages_friend_id == payload.user.id ) {
        //     this.$parent.$refs.pm_messages.createMessageElement(payload.message);
        //     // form_data.append('user_notification_flag', 'reed');  // já é reed como padrão
        //   } else {
        //     root_.$store.dispatch("update_add_notifications_count");
        //     emitter.emit('notifications::new-notifications-available', payload);
        //   }
        //
        //
        //   break;
      }
    },
  },
  computed: {
    ...mapGetters([
      // 'logged', 'token', 'id', 'nickname', 'email', 'ref', 'age', 'switch', "checkMobile", "current_session", "sessions"
        'switch', 'token', 'id', 'nickname'
    ]),
  }
}
</script>


<style scoped>

</style>